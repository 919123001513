.ck.ck-editor__editable_inline {
    transition: background-color .5s ease-out;
}

.editor-character-limiter svg {
    margin: 0.25em 0.5em 0.25em 0;

    circle:nth-child(1) {
        stroke: #fafafa;
    }

    circle:nth-child(2) {
        transform: rotate(-90deg);
        transform-origin: center;
        stroke: #28a745;
    }

    text {
        font-size: 13px;
        font-weight: bold;
    }
}

.limit-close {
    .editor-character-limiter svg circle:nth-child(2) {
        stroke: #fd7e14;
    }
}

.limit-exceeded {
    .ck.ck-editor__editable_inline {
        background-color: hsl(0, 100%, 97%) !important;
    }

    .editor-character-limiter svg {
        circle:nth-child(2) {
            stroke: #dc3545;
        }

        text {
            fill: #dc3545;
        }
    }
}

.editor-character-limiter span {
    display: inline-block;
    margin-top: 1px;
    vertical-align: middle;
    font-size: 0.8em;
    color: #4c4c4c;
    border-bottom: 1px dashed #4c4c4c;
    cursor: help;
}
