.user-general {
    .gender {
        .choose-image-content {
            .fas {
                padding: 5px;
                width: 3rem;
                font-size: 2rem;
            }
        }
    }
}
