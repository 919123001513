.js-picture-list {
    width: 100% !important;

    .filter {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid lightgrey;

        #category {
            margin: 0;
        }
    }

    .item {
        margin-bottom: 1em;

        a {
            display: inline-block;
        }
        img {
            margin-bottom: 0;
            width: 100%;
        }
    }

    .actions {
        text-align: center;

        .loadMore {
            cursor: pointer;
        }
    }
}

/* Fix image width and height */
.card-user-entry,
.picture-review,
.js-picture-check,
.js-picture-show {
    .image-overlay {
        aspect-ratio: auto;
        width: auto;
    
        img {
            object-fit: contain;
        }
    }
}

.js-picture-show {
    .picture {
        text-align: center;

        img {
            max-width: 100%;
            max-height: 600px;
        }
    }
    .image-overlay {
        .overlay {
            height: 40px;
        }
        .text {
            position: relative;
            opacity: 0.6;

            .text-icon-search {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .fas {
                font-size: 30px;
            }
        }
    }

    .show-rating {
        .fas {
            width: 2.1rem;
            text-align: center;
            font-size: 1.7rem;
        }
        .rating-value {
            display: inline-block;
            width: 5rem;
            line-height: 1.8rem;
            text-align: right;
            vertical-align: top;
        }
        .fa-star {
            width: 1.7rem;
            font-size: 1.7rem;
        }
        .fa-star-half {
            width: 1.0rem;
            font-size: 1.7rem;
        }
    }
}

.js-picture-check {
    .picture-to-check {
        position: relative;
        min-height: 400px;

        .spinner {
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .spinner-border {
                width: 3rem;
                height: 3rem;
            }
        }
    }

    .picture-vote {
        padding: 15px 3px;
        text-align: center;
        @include user-select(none);

        div {
            display: inline-block;
            position: relative;
            margin: 0 1px 0 0;
            padding: 0;
            width: 120px;
            height: 120px;

            border-radius: 3px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
            text-shadow: 1px 1px 0 rgba(64, 64, 64, 0.8);
            opacity: 0.7;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: top center;

            &:hover {
                cursor: pointer;
                opacity: 1;
            }

            a {
                display: block;
                position: absolute;
                bottom: 0;
                padding: 4px 8px 1px 8px;
                width: 120px;

                font-size: 14px;
                line-height: 20px;
                text-align: center;
                text-decoration: none;
                color: #FFFFFF;
                background: green;
            }
        }

        #neutral {
            background-color: rgba(127, 189, 26, 0.2);
            background-image: url('../../public/images/check/check-40319.svg');
            background-size: 80%;

            a {
                background: #7FBD1A;
                background: linear-gradient(to bottom, #7FBD1A 0%, #5c7f2c 100%);
            }
        }

        #illegal {
            background-color: rgba(213, 1, 19, 0.2);
            background-image: url('../../public/images/check/cross-296507.svg');

            a {
                background: #D50113;
                background: linear-gradient(to bottom, #D50113 0%, #5f0000 100%);
                /*background: #434343;*/
                /*background: linear-gradient(to bottom, #434343 0%, #070707 100%);*/
            }
        }

        #next {
            background-color: rgba(245, 245, 245, 0.2);
            background-image: url('../../public/images/check/right-297788.svg');
            color: #000000;
            text-shadow: 1px 1px 0 rgba(160, 160, 160, 0.8);

            a {
                color: #000000;
                background: #f5f5f5;
                background: linear-gradient(to bottom, #f5f5f5 0%, #c9c9c9 100%);
            }
        }
    }
}
