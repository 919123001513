@use 'sass:math';

$backgroundColor: #F4E3D6;

html {
    height: 100%;
    /*box-sizing: border-box;*/
}
.page {
    min-height: calc(100vh - 56px - 56px);
}

/**,
*:before,
*:after {
    box-sizing: inherit;
}*/

body {
    position: relative;
    margin: 0;
    padding-top: 56px;
    padding-bottom: 56px;
    min-height: 100%;
    font-family: "Helvetica Neue", Arial, sans-serif;
    background-color: #EDEDED;
    background-image: url('../../public/images/background4.png');
    background-position: top;
    background-attachment: fixed;
}

.page {
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: $backgroundColor;
    border-left: 1px dotted #000000;
    border-right: 1px dotted #000000;
}
footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    color: #FFFFFF;
    background-color: #efefef;
    text-align: center;
    //z-index: 9999;
}

/* Move anker links */
.anker {
    position: relative;
    top: -60px;
}

/* Notifications */
.toast-top-left,
.toast-top-right {
    top: 70px;
}

/* Navigation */
nav {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.375);

    .navbar-toggler {
        outline: none;
    }
    form {
        margin-bottom: 0;
    }
    .collapse-toggle .dropdown-item {
        color: rgba(255,255,255,.5);
        background-color: transparent;
    }
    .collapse-toggle .dropdown-item:hover {
        color: rgba(255,255,255,.75);
    }
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
    nav {
        .scrollable-menu {
            height: auto;
            max-height: calc(100vh - 56px);
            overflow-x: hidden;
        }
    }
}

nav,
.nav {
    .dropdown-toggle {
        position: relative;

        &::after {
            position: absolute;
            top: 18px;
            right: 18px;
        }
    }

    .navbar-brand {
        img {
            width: 252px;
            height: 30px;
        }
    }

    .avatar a {
        &, &:hover, &:link, &:active {
            color: #ffffff;
            text-decoration: none;
            outline: none;
        }
    }
}

/* Navigation - Icons */
.navbar-nav,
.nav {
    a {
        outline: none;
    }

    svg {
        min-width: 22px;
        text-align: center;
    }
    .nav-administration {
        svg {
            color: #673ab7;
        }
    }
    .nav-user .fas {
        svg {
            color: #EDA833;
        }
    }
    .nav-picture {
        svg {
            color: #8CC37B;
        }
    }
    .nav-story {
        svg {
            color: #AA0000;
        }
    }
    .nav-survey {
        svg {
            color: #ff9800;
        }
    }
    .nav-category {
        svg {
            color: #795548;
        }
    }
    .nav-user {
        svg {
            color: #017BC8;
        }
    }
    .nav-partner {
        svg {
            color: #9e9e9e;
        }
    }
    .nav-schambereich {
        svg {
            color: #607d8b;
        }
    }
    .nav-information {
        svg {
            color: #9e9e9e;
        }
    }
}

/* Navigation - Login */
.menuUser,
.menuUser:link,
.menuUser:focus,
.menuUser:active {
    display: inline-block;
    color: white;
    text-decoration: none;
    outline: none;
}
.menuUser:hover {
    color: #eaeaea;
}
#toggleMenuUser {
    border-radius: 0 0 4px 4px;
    margin-top: 12px;
    right: 0;

    &.menuUserLoginDropdown {
        margin-top: 8px;
        min-width: 320px;
    }
}
@include media-breakpoint-down(md) {
    #toggleMenuUser {
        border-radius: 4px;
    }
}

/* Navigation - Sidebar */
#sidebar .nav {
    @include user-select(none);

    a {
        color: #3a3a3a;
    }

    > .nav-item:first-child {
        &,
        > a:first-child {
            border-radius: 4px 4px 0 0;
        }
    }

    > .nav-item:last-child {
        &,
        > a:last-child {
            &,
            > div,
            .dropdown-item:last-child {
                border-radius: 0 0 4px 4px;
            }
        }
        > a.collapsed {
            border-radius: 0 0 4px 4px;
            transition: 400ms border-radius ease 400ms;
        }
    }

    > .nav-item {
        background-color: #FFFFFF;
        border-bottom: 1px solid #F1F1F1;

        > a {
            background-color: #FFFFFF;

            &:hover {
                background-color: #e4e4e4;
            }
        }

        &.collapse-toggle > div {
            background-color: #F1F1F1;

            > a:hover {
                background-color: #e4e4e4;
            }
        }
    }
}

/* Collapse toggler */
.collapse-toggle .dropdown-toggle::after {
    transition: transform 400ms ease;
    transform: rotate(-180deg) !important;
}
.collapse-toggle .dropdown-toggle.collapsed::after {
    transition: transform 400ms ease;
    transform: rotate(0deg) !important;
}

/* Pagination */
.pagination {
    margin: 0;

    .page-item {
        .page-link {
            color: #337AB7;
            background-color: #ffffff;
            border-color: #337AB7;
        }
        &.disabled .page-link {
            color: #6c757d;
            border-color: #337AB7;
        }
        &.active .page-link,
        &:hover .page-link {
            color: #ffffff;
            background-color: #337AB7;
        }
    }
}

/* Spinner */
.spinner1 {
    height: 10px;

    .dot {
        width: 10px;
        height: 10px;
        border: 2px solid black;
        border-radius: 50%;
        float: left;
        margin: 0 5px;
        transform: scale(0);
        animation: spinner1 1000ms ease infinite 0ms;

        &:nth-child(2) {
            animation: spinner1 1000ms ease infinite 300ms;
        }
        &:nth-child(3) {
            animation: spinner1 1000ms ease infinite 600ms;
        }
    }
}
@keyframes spinner1 {
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* Ratings widget */
.rating {
    display: inline-block;
    border: 0;

    /* Hide radio */
    > input {
        display: none;
    }
    /* Order correctly by floating highest to the right */
    > label {
        float: right;
        font-size: 1.7rem;
        cursor: pointer;
    }
    /* Ratings widget - Click + hover color */
    input:checked ~ label, /* color current and previous stars on checked */
    label:hover, label:hover ~ label {
        /* color previous stars on hover */
        color: #73B100;
    }
    /* Ratings widget - Hover highlights */
    input:checked + label:hover, input:checked ~ label:hover, /* Ratings widget - highlight current and previous stars */
    input:checked ~ label:hover ~ label, /* Ratings widget - highlight previous selected stars for new rating */
    label:hover ~ input:checked ~ label {
        /* Ratings widget - highlight previous selected stars */
        color: #A6E72D;
    }
}

/* File upload preview */
input.file-upload-preview {
    width: 100% !important;
}
.file-upload-images {
    width: 100%;
    padding: 20px;
    background-color: aliceblue;
    outline: 2px dashed black;
    outline-offset: -10px;

    img {
        display: inline-block;
        margin: 0 auto;
        padding: 4px;
        height: 200px;
        vertical-align: middle;
    }
}

/* Favorite */
.js-favorite-toggle {
    display: inline-block;
    cursor: pointer;

    .add span,
    .remove span {
        //text-decoration: underline;
    }

    .add {
        display: inline-block;
    }
    .remove {
        display: none;
    }
    &.isFavorite .add {
        display: none;
    }
    &.isFavorite .remove {
        display: inline-block;
    }
}

/* Tagify */
tags {
    display: flex !important;
    background-color: #ffffff;
    border-radius: 4px;
}
tags > div > input ~ span {
    line-height: 2.1em;
}

/* Custom radio box */
.form-radio-style {
    @include user-select(none);
    -webkit-touch-callout: none;
    padding-left: 0;

    & > input {
        visibility: hidden;
        position: absolute;
    }

    & > label {
        cursor: pointer;
    }

    & > label > i.toggle {
        display: inline-block;
        vertical-align: middle;
        width:  16px;
        height: 16px;
        margin-top: -3px;
        border-radius: 50%;
        transition: 0.2s;
        box-shadow: inset 0 0 0 8px #ffffff;
        border: 1px solid #343a40;
        background-color: #343a40;
    }
    & > label:hover > i.toggle {
        box-shadow: inset 0 0 0 3px #ffffff;
        background-color: #343a40;
    }
    & > input:checked + label > i.toggle {
        box-shadow: inset 0 0 0 3px #ffffff;
        background-color: #ff9800;
    }

    &.disabled {
        & > label > i.toggle {
            color: #EBEBEB;
        }
        & > label > i.toggle {
            border: 1px solid #EBEBEB;
            background-color: #EBEBEB;
        }
        &:hover > label > i.toggle {
            box-shadow: inset 0 0 0 8px #ffffff;
            background-color: #EBEBEB;
        }
    }
}

/* Custom check box (true/false) */
.form-check-bool {
    @include user-select(none);
    -webkit-touch-callout: none;
    padding-left: 0;

    & > input {
        visibility: hidden;
        position: absolute;
    }

    & > label {
        cursor: pointer;
    }

    & > label > i.toggle {
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        height: 16px;
        margin-top: -3px;
        border-radius: 8px;
        transition: 0.2s;
        box-shadow: inset 0 0 0 8px #ffffff;
        border: 1px solid #343a40;
        background-color: #343a40;
    }

    & > input + label > i.toggle:after {
        content: "";
        display: block;
        height: 12px;
        width:  12px;
        margin: 1px 2px;
        border-radius: inherit;
        transition: inherit;
        background-color: #AA0000;
    }

    & > input:checked + label > i.toggle:after {
        margin-left: 14px;
        background-color: #8CC37B;
    }

    &.disabled {
        & > label > i.toggle {
            border: 1px solid #EBEBEB;
            background-color: #EBEBEB;
        }
        & > input + label > i.toggle:after {
            background-color: #EBEBEB;
        }
    }
}

/* Radio buttons as images */
.choose-image {
    @include user-select(none);

    label > input {
        position: absolute;
        visibility: hidden;

        & + div {
            cursor: pointer;
            min-width: 96px;
            border: 3px solid transparent;
        }
        & + div > .choose-image-content {
            text-align: center;
            background-color: #ffffff;
        }
        & + div > .choose-image-title {
            padding: 2px 5px;
            background-color: #343A40;
            color: #ffffff;
            text-align: center;
            border-top: 1px solid slategrey;
        }
        &:checked + div {
            border-color: #AA0000;
        }
    }
}

/* Image overlay */
.image-overlay-link {
    display: inline-block;
    width: 100%;
}
.image-overlay {
    position: relative;
    display: inline-block;
    width: 100%;
    aspect-ratio: 4/3;

    @include user-select(none);
    outline: none;
    cursor: pointer;

    > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 1px solid #021a40;
    }
    > .overlay > .text {
        height: 100%;
        padding: 4px 7px;
        color: #ffffff;
        font-size: 14px;
    }
    > .overlay {
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        color: white;
        overflow: hidden;
    }

    /* Fade */
    &.overlay-fade {
        > .overlay {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            -webkit-transition: opacity 400ms ease;
            transition: opacity 400ms ease;
        }
        &:hover > .overlay {
            opacity: 1;
        }
        &.invert > .overlay {
            opacity: 1;
        }
        &.invert:hover > .overlay {
            opacity: 0;
        }
    }

    /* Bottom to top */
    &.overlay-bottom {
        > .overlay {
            left: 0;
            right: 0;
            bottom: 0;
            height: 0;
            -webkit-transition: height 400ms ease;
            transition: height 400ms ease;
        }
        &:hover > .overlay {
            height: 100%;
        }
        &.invert > .overlay {
            height: 100%;
        }
        &.invert:hover > .overlay {
            height: 0;
        }
    }

    /* Top to bottom */
    &.overlay-top {
        > .overlay {
            top: 0;
            left: 0;
            right: 0;
            bottom: 100%;
            height: 0;
            -webkit-transition: height 400ms ease, bottom 400ms ease;
            transition: height 400ms ease, bottom 400ms ease;
        }
        &:hover > .overlay {
            bottom: 0;
            height: 100%;
        }
        &.invert > .overlay {
            bottom: 0;
            height: 100%;
        }
        &.invert:hover > .overlay {
            bottom: 100%;
            height: 0;
        }
    }

    /* Left to right */
    &.overlay-left {
        > .overlay {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 0;
            -webkit-transition: width 400ms ease;
            transition: width 400ms ease;
        }
        &:hover > .overlay {
            width: 100%;
        }
        &.invert > .overlay {
            width: 100%;
        }
        &.invert:hover > .overlay {
            width: 0;
        }
    }

    /* Right to left */
    &.overlay-right {
        > .overlay {
            top: 0;
            left: 100%;
            right: 0;
            bottom: 0;
            width: 0;
            -webkit-transition: width 400ms ease, left 400ms ease;
            transition: width 400ms ease, left 400ms ease;
        }
        &:hover > .overlay {
            left: 0;
            width: 100%;
        }
        &.invert > .overlay {
            left: 0;
            width: 100%;
        }
        &.invert:hover > .overlay {
            left: 100%;
            width: 0;
        }
    }

    /* Fade */
    &.overlay-toolbar-bottom {
        > .overlay {
            left: 0;
            right: 0;
            bottom: 0;
            height: 60px;
            opacity: 1;
            -webkit-transition: opacity 400ms ease;
            transition: opacity 400ms ease;
        }
        &:hover > .overlay {
            opacity: 1;
        }
        &.invert > .overlay {
            opacity: 1;
        }
        &.invert:hover > .overlay {
            opacity: 0;
        }
    }
}

/* Comment */
.comment {
    .card-header {
        &:after,
        &:before{
            position: absolute;
            top: 15px;
            left: -16px;
            right: 100%;
            width: 0;
            height: 0;
            display: block;
            content: " ";
            border-color: transparent;
            border-style: solid solid outset;
            pointer-events: none;
        }
        &:before{
            border-right-color: #dddddd;
            border-width: 8px;
        }
        &:after{
            border-width: 7px;
            border-right-color: #f7f7f7;
            margin-top: 1px;
            margin-left: 2px;
        }
    }
    .card-body > :last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

/* Rules */
.rules {
    h6 {
        font-weight: bold;
    }
    li {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

/* Card hamburger menu */
.card-header {
    .menu-hamburger {
        $width: 30px;
        position: absolute;
        top: 5px;
        right: 5px;
        width: $width;
        height: $width;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;

        &:after {
            border: none;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }

        svg {
            margin-top: 7px;
            margin-left: 8px;
            //vertical-align: text-bottom;
        }
    }
    .card-header-title {
        display: inline-block;
        margin-right: 25px;
    }
}

/* Card Picture, Story & Survey */
.card-user-entry {
    .card-header {
        position: relative;
    }

    .card-body {
        .item-content {
            position: relative;
            margin-left: 0;
            margin-right: 0;

            > div:first-child {
                margin-bottom: 40px;
            }
            > div:last-child {
                position: absolute;
                bottom: 0;
            }
        }
        .details {
            border-left: 1px solid #D8D8D8;
        }
    }
}
@include media-breakpoint-down(xs) {
    .card-user-entry {
        .card-body {
            .details {
                margin-top: 10px;
                border-left: none;
            }
        }
    }
}

/* One Revive */
.one-revive {
    margin: 20px 0;
}
.one-revive-fixed {
    position: fixed;
    top: 50px;
    z-index: 1030;
}

/* Advertise */
.sponsored {
    display: block !important;
    opacity: 1 !important;
    margin-bottom: 18px;

    &:before {
        content: "Sponsored";
        display: block;
        margin: 0;
        padding: 0;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #999999;
    }
}
.sponsored-adblock {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px dashed #D00110;
    text-align: center;
    background-color: #ffa396;
    font-size: 14px
}

.advertise-right {
    position: fixed;
    margin: 0 0 0 50%;
    top: 85px;
    width: 260px;
    text-align: left;
    z-index: 10300;

    @each $breakpoint, $container-max-width in $container-max-widths {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            left: math.div($container-max-width, 2);
        }
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

/* Password Strength */
.password-strength-meter {
    /* Reset the default appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    margin: 0 auto 1em;
    padding: 0;
    width: 100%;
    height: 0.5em;

    /* Applicable only to Firefox */
    background: none;
    background-color: rgba(0, 0, 0, 0.1);

    &::-webkit-meter-bar {
        background: none;
        background-color: rgba(0, 0, 0, 0.1);
    }

    /* Webkit based browsers */
    &[value="1"]::-webkit-meter-optimum-value { background: #D00110; }
    &[value="2"]::-webkit-meter-optimum-value { background: #F07502; }
    &[value="3"]::-webkit-meter-optimum-value { background: #FFBC00; }
    &[value="4"]::-webkit-meter-optimum-value { background: #7CBC19; }
    &[value="5"]::-webkit-meter-optimum-value { background: #4caf50; }


    /* Gecko based browsers */
    &[value="1"]::-moz-meter-bar { background: #D00110; }
    &[value="2"]::-moz-meter-bar { background: #F07502; }
    &[value="3"]::-moz-meter-bar { background: #FFBC00; }
    &[value="4"]::-moz-meter-bar { background: #7CBC19; }
    &[value="5"]::-moz-meter-bar { background: #4caf50; }
}
.password-strength-text {
    margin-top: -1.4em;
    font-size: 14px;
}

/* Tag */
.tag {
    display: inline-block;
    position: relative;
    margin: 0 4px 6px 0;
    padding: 0 1.5em 0 0.9em;
    height: 2em;

    color: #999999;
    background: #eeeeee;
    border-radius: 3px 0 0 3px;
    font: 12px/1.5 'PT Sans', serif;
    line-height: 2em;
    text-decoration: none;

    -webkit-transition: color 0.4s, background-color 0.4s;
    -moz-transition: color 0.4s, background-color 0.4s;
    transition: color 0.4s, background-color 0.4s;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;

        background: $backgroundColor;
        border-bottom: 1.0em solid transparent;
        border-left: 0.9em solid #eeeeee;
        border-top: 1em solid transparent;

        -webkit-transition: border-color 0.4s;
        -moz-transition: border-color 0.4s;
        transition: border-color 0.4s;
    }

    &:hover {
        background-color: #5a6268;
        color: #ffffff;
        text-decoration: none;
    }
    &:hover:after {
        border-left-color: #5a6268;
    }

    &.tag-bg-white {
        &:after {
            background: #ffffff;
        }
    }
}
