@import '~@fancyapps/ui/src/Fancybox/Fancybox.scss';

:root {
    --fancybox-content-bg: #F4E3D6;
}

@include media-breakpoint-down(sm) {
    .fancybox__toolbar {
        .fancybox__button--prev,
        .fancybox__counter,
        .fancybox__button--next {
            display: none;
        }
    }
}

/*@import '~@fancyapps/fancybox/dist/jquery.fancybox.css';

[data-fancybox] {
    outline: none;
}

.fancybox-container {
    .fancybox-bg {
        background-color: #333333;
        opacity: 0.95;
    }
    .fancybox-caption {
        &:before {
            background-image: none;
        }
        &:after {
            border: 0;
        }
    }
    .fancybox-toolbar .fancybox-button {
        outline: none;
    }
}
.fancybox-share__button {
    .fab {
        font-size: 19px;
    }
}
.fancybox-share__button--tumblr {
    background-color: #36465D;

    &:hover {
        background-color: #24364c;
    }
}
.fancybox-share__button--telegram {
    background-color: #0088CC;

    &:hover {
        background-color: #0074b4;
    }
}
.fancybox-share__button--threema {
    background-color: #333333;

    &:hover {
        background-color: #000000;
    }
}
.fancybox-share__button--whatsapp {
    background-color: #25D366;

    &:hover {
        background-color: #24ba5d;
    }
}

.fancybox-content {
    background-color: $backgroundColor;
}
*/