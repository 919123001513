.progress-bar {
    background-color: darkslategrey !important;
}

.js-survey-show {
    .question {
        border-bottom: 1px dotted #000000;
    }

    .progress {
        position: relative;
        background-color: rgba(47, 79, 79, 0.39);

        span {
            position: absolute;
            display: block;
            width: 100%;
        }
    }
}

.js-survey-list {
    .pagination-survey {
        margin-top: 15px;
        text-align: center;
    }
}

.tx-survey-vote {
    .question {
        border-bottom: 1px dotted #000000;
    }
}
