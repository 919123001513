.js-administration-panel {
    border: 2px dashed #D95870;

    .toggler {
        display: block;
        position: relative;
        padding: 8px 14px;
        color: #333333;

        cursor: pointer;
        @include user-select(none);

        &:hover {
            background-color: #ececec;
            text-decoration: none;
        }

        span {
            position: absolute;
            top: 12px;
            right: 12px;

            transition: transform 400ms ease;
            transform: rotate(-180deg) !important;
        }
        &.open span {
            transition: transform 400ms ease;
            transform: rotate(0deg) !important;
        }
    }

    .toggler-content {
        display: none;
        padding: 8px 14px;
        border-top: 1px solid #E5E5E5;
    }
}
