.js-socialShare {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;

    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.8);
    pointer-events: none;
    display: none;

    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;

    @include user-select(none);

    &.active {
        opacity: 1;
        pointer-events: all;
    }

    .icon-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: center;

        &:before {
            content: '';
            height: 100%;
            display: inline-block;
            vertical-align: middle;
        }
        .centered {
            display: inline-block;
            padding: 1em;
            vertical-align: middle;
            text-align: left;
            width: 90%;
            max-width: 725px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            text-align: center;
            opacity: 0;

            -webkit-transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -o-transition: all 200ms ease-in-out;
            -ms-transition: all 200ms ease-in-out;
            transition: all 200ms ease-in-out;

            pointer-events: none;

            &.active {
                opacity: 1;
                pointer-events: auto;
            }

            li {
                display: inline-block;
                width: 80px;
                //height: 110px;
                margin: 10px;

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    width: 80px;
                    height: 80px;
                    margin: 0 0 10px 0;

                    color: #ffffff;
                    background-color: #617385;
                    border-radius: 10px;
                    font-size: 2.5em;
                    text-decoration: none;
                    outline: none;

                    background-image: -webkit-radial-gradient(center center, 80px 80px, rgba(255, 255, 255, .15) 0%, rgba(0, 0, 0, .15) 100%);
                    background-image: -moz-radial-gradient(center center, 80px 80px, rgba(255, 255, 255, .15) 0%, rgba(0, 0, 0, .15) 100%);
                    background-image: -o-radial-gradient(center center, 80px 80px, rgba(255, 255, 255, .15) 0%, rgba(0, 0, 0, .15) 100%);
                    background-image: -ms-radial-gradient(center center, 80px 80px, rgba(255, 255, 255, .15) 0%, rgba(0, 0, 0, .15) 100%);
                    background-image: radial-gradient(center center, 80px 80px, rgba(255, 255, 255, .15) 0%, rgba(0, 0, 0, .15) 100%);

                    &:hover {
                        background-image: -webkit-radial-gradient(center center, 80px 80px, rgba(255, 255, 255, .3) 0%, rgba(0, 0, 0, .3) 100%);
                        background-image: -moz-radial-gradient(center center, 80px 80px, rgba(255, 255, 255, .3) 0%, rgba(0, 0, 0, .3) 100%);
                        background-image: -o-radial-gradient(center center, 80px 80px, rgba(255, 255, 255, .3) 0%, rgba(0, 0, 0, .3) 100%);
                        background-image: -ms-radial-gradient(center center, 80px 80px, rgba(255, 255, 255, .3) 0%, rgba(0, 0, 0, .3) 100%);
                        background-image: radial-gradient(center center, 80px 80px, rgba(255, 255, 255, .3) 0%, rgba(0, 0, 0, .3) 100%);
                    }

                    &.wikipedia {
                        background-color: #303030;
                    }
                    &.eMail {
                        background-color: #434343;
                    }
                    &.wordpress {
                        background-color: #444;
                    }
                    &.deviantArt {
                        background-color: #40504c;
                    }

                    &.forrst {
                        background-color: #254619;
                    }
                    &.technorati {
                        background-color: #48aa26;
                    }
                    &.evernote {
                        background-color: #78ba4e;
                    }
                    &.zerply {
                        background-color: #99bd67;
                    }
                    &.xing {
                        background-color: #036567;
                    }

                    &.vimeo {
                        background-color: #627486;
                    }
                    &.tumblr {
                        background-color: #365471;
                    }
                    &.facebook {
                        background-color: #3b5998;
                    }
                    &.digg {
                        background-color: #1b5891;
                    }
                    &.delicious {
                        background-color: #3274d1;
                    }

                    &.twitter {
                        background-color: #00b6f3;
                    }
                    &.posterous {
                        background-color: #8ab4cd;
                    }

                    &.pinterest {
                        background-color: #cb2027;
                    }
                    &.path {
                        background-color: #bf3119
                    }
                    &.googlePlus {
                        background-color: #cf4231;
                    }
                    &.flud {
                        background-color: #ff503e;
                    }

                    &.stumbleUpon {
                        background-color: #eb4924;
                    }
                    &.reddit {
                        background-color: #ff500f;
                    }
                    &.rss {
                        background-color: #ff7722;
                    }
                    &.scoutzie {
                        background-color: #f87242;
                    }
                    &.blogger {
                        background-color: #fc9c4a;
                    }

                    &.yahoo {
                        background-color: #640e6b;
                    }
                    &.flickr {
                        background-color: #ff0084;
                    }
                    &.dribbble {
                        background-color: #ff0084;
                    }
                    &.telegram {
                        background-color: #0088CC;
                    }
                    &.threema {
                        background-color: #333333;
                    }
                    &.whatsapp {
                        background-color: #25D366;
                    }

                }
                span {
                    text-align: center;
                    font-family: sans-serif;
                    font-size: 11px;
                    font-weight: bold;
                    text-shadow: 0 2px 3px rgba(0, 0, 0, .9);
                    color: #e5e5e5;
                    font-style: normal !important;
                }
            }
        }
    }

    /* Animations */
    .icon-container ul {
        -webkit-transform-origin: center 20%;
        -webkit-transform: scale(0.8);

        -moz-transform-origin: center 20%;
        -moz-transform: scale(0.8);

        -o-transform-origin: center 20%;
        -o-transform: scale(0.8);

        -ms-transform-origin: center 20%;
        -ms-transform: scale(0.8);

        transform-origin: center 20%;
        transform: scale(0.8);
    }
    &.active .icon-container ul {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
.opaque {
    background: rgba(0, 0, 0, 0.2) !important;
}

.blurred {
    -webkit-transition: -webkit-filter 300ms ease-in-out;
    -moz-transition: -moz-filter 300ms ease-in-out;
    -o-transition: -o-filter 300ms ease-in-out;
    -ms-transition: -ms-filter 300ms ease-in-out;
    transition: filter 300ms ease-in-out;
    -webkit-filter: blur(5px);
}

@include media-breakpoint-down(md) {
    .js-socialShare .icon-container ul li {
        width: 60px;
        a {
            width: 60px;
            height: 60px;
            font-size: 2em;
            border-radius: 6px;
        }
    }
}

@include media-breakpoint-down(xs) {
    .js-socialShare .icon-container ul li {
        width: 40px;
        a {
            width: 40px;
            height: 40px;
            font-size: 1.5em;
            border-radius: 4px;
        }
        span {
            display: none;
        }
    }
}
