$link-decoration: none;
$link-hover-decoration: underline;

@import '../../node_modules/bootstrap/scss/bootstrap';

$tag-hover: #F4E3D6;
@import '../../node_modules/@yaireo/tagify/src/tagify';

@import '../../node_modules/toastr/toastr';

@import 'mixins';
@import 'global';
@import 'partner';
@import 'picture';
@import 'story';
@import 'survey';
@import 'user';
@import 'fancybox';
@import 'administration-panel';
@import 'socialShare';
@import 'ckeditor';
