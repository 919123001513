.js-story-list {
    .filter {
        padding-bottom: 16px;

        #category {
            margin: 0;
        }
    }

    .pagination-stories {
        margin-top: 15px;
        text-align: center;
    }
}

.js-story-show .show-rating {
    .fas {
        width: 2.1rem;
        text-align: center;
        font-size: 1.7rem;
    }
    .rating-value {
        display: inline-block;
        width: 5rem;
        line-height: 1.8rem;
        text-align: right;
        vertical-align: top;
    }

    .fa-star {
        width: 1.7rem;
        font-size: 1.7rem;
    }
    .fa-star-half {
        width: 1.0rem;
        font-size: 1.7rem;
    }
}
