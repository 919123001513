.partner-amateur {
    .video-image {
        position: relative;

        &:after {
            content: url('../../public/images/play.png');
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        img {
            max-width: 100%;
        }
    }

    .item {
        margin-top: 30px;

        a {
            color: #0088CC;
            text-decoration: none;

            &:hover {
                color: #005580;
            }
        }
        h4 {
            margin-top: 0;

            a {
                color: #333;

                &:hover {
                    color: #333;
                    text-decoration: none;
                }
            }
        }
        .details {
            font-size: 12px;
            color: #9B9B9B;
        }
    }
}
